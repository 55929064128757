import { useLocation } from 'react-router-dom';
const AVAILABLE_FOR_ALL_PAGES = true;
const getDataUsingSplit = data => {
  // reason to adding filter here is to remove empty string
  return data
    ?.split(',')
    ?.map(item => item?.trim())
    ?.filter(item => item?.length);
};

const useClarityAvailability = () => {
  const location = useLocation();
  const { ms_clarity_script, ms_clarity_url, ms_clarity_enable } = STORE_CONFIG;

  // Early exit if required configuration is missing
  if (!ms_clarity_script || !ms_clarity_enable) {
    return null;
  }

  const clarityScripts = getDataUsingSplit(ms_clarity_script);
  const clarityUrls = getDataUsingSplit(ms_clarity_url);

  // Condition to check if clarity script, in case adding space in input field in BE
  if (!clarityScripts?.length) return null;

  // Extract pathname without query params
  const pathnameWithoutQuery = location.pathname.split('?')[0];

  const isClarityAvailable = clarityUrls?.length
    ? clarityUrls?.includes(pathnameWithoutQuery)
    : AVAILABLE_FOR_ALL_PAGES;

  const isClarityScriptShouldAdd = isClarityAvailable;

  return {
    clarityScripts,
    isClarityScriptShouldAdd,
  };
};

export default useClarityAvailability;
