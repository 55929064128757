// import { gql: g } from '@apollo/client';
import * as apollo from '@apollo/client';
//! STOPGAP FIX
const apollo1: any = apollo;
const gql = apollo1?.default?.gql || apollo1?.gql;

export const CategoryFragment = gql`
  # eslint-disable-next-line @graphql-eslint/require-id-when-available
  fragment CategoryFragment on CategoryTree {
    uid
    meta_title
    meta_keywords
    meta_description
    url_path
    canonical_url
  }
`;

export const ProductsFragment = gql`
  fragment ProductsFragment on Products {
    items {
      id
      uid
      name
      type_id
      RequestLiveDemoCTA
      hero_variant
      ecommerce_redirect_text
      ecommerce_redirect
      ecommerce_redirect_link
      saleable_on_both
      price_range {
        minimum_price {
          regular_price {
            value
            currency
          }
          final_price {
            value
            currency
          }
          discount {
            amount_off
            percent_off
          }
        }
        maximum_price {
          regular_price {
            value
            currency
          }
          final_price {
            value
            currency
          }
          discount {
            amount_off
            percent_off
          }
        }
      }
      sku
      small_image {
        url
      }
      stock_status
      rating_summary
      __typename
      url_key

      ... on ConfigurableProduct {
        # eslint-disable-next-line @graphql-eslint/require-id-when-available
        configurable_options {
          attribute_code
          attribute_id
          uid
          label
          # eslint-disable-next-line @graphql-eslint/require-id-when-available
          values {
            uid
            default_label
            label
            store_label
            use_default_value
            value_index
            swatch_data {
              ... on ImageSwatchData {
                thumbnail
              }
              value
            }
          }
        }
        variants {
          attributes {
            code
            value_index
            label
            uid
          }
          product {
            ecommerce_redirect_text
            ecommerce_redirect
            ecommerce_redirect_link
            saleable_on_both
            uid
            only_x_left_in_stock
            product_qty
            sku
            stock_status
            small_image {
              url
            }
            price_range {
              minimum_price {
                regular_price {
                  value
                  currency
                }
                final_price {
                  value
                  currency
                }
                discount {
                  amount_off
                  percent_off
                }
              }
            }
          }
        }
      }
    }
    page_info {
      total_pages
    }
    total_count
  }
`;
