import { Disclosure, Popover } from '@headlessui/react';
import styles from './style.module.scss';
import { useUserState } from 'packages/framework/context/user';
import { useAppContext } from 'packages/framework/context/app';
import { useWindowSize } from 'packages/framework/hooks/useWindowSize';
import { useCallback, useMemo } from 'react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { Link } from 'react-router-dom';

const MAPPING = {
  Fans: 'Fans',
  'Mixer Grinder': 'Mixer Grinder',
  'Smart Locks': 'Smart Locks',
};

const BOTTOM_STRIP_IMAGES = [
  '/icons/Fan.svg',
  '/icons/MG.svg',
  '/icons/SL.svg',
  '/icons/Support.svg',
];

export const MobileLink = ({ name, url_key, children, classes, image }) => {
  const isSpaceAppliead = name === 'Smart Locks';
  
  return (
    <li className={`${styles.mobileLink} py-2 ${classes || ''} text-[#1A1819] ${isSpaceAppliead ? 'ml-[15px]' : ''}`}>
      {url_key ? (
        <Link to={`${url_key}${CATEGORY_URL_SUFFIX}`} className="flex flex-col items-center justify-between h-full">
          <img src={image} alt="test" />
          <span className='inline-block py-0 mt-[6px]'>{name}</span>
        </Link>
      ) : (
        <div className="px-3 mt-[6px]">
          <span>{name}</span>
        </div>
      )}
      {children}
    </li>
  );
};

const BottomMenuItemContainer = ({ item, uniqueKey, image }) => {
  const { name, url_key, items } = item;
  const hasChildren = Boolean(items) && items.length > 0;
  if (items?.length) {
    return (
      <Popover key={uniqueKey} className="flex items-center">
        {({ open }) => (
          <>
            <Popover.Button className="py-2 px-4 flex flex-col items-center outline-none">
              <img
                src={image}
                alt=""
                className="aspect-[3/2] object-contain w-[40px] h-[40px]"
              />
              <div className="flex items-center">
                <span
                  className={`uppercase text-[11px] ${open ? 'text-[#fdb913]' : 'text-[#1A1819]'}`}
                >
                  {name}
                </span>
                <ChevronDownIcon
                  className={`${open ? 'rotate-180' : ''} w-5 h-5 ml-2`}
                  aria-hidden="true"
                  fill="#fdb913"
                />
              </div>
            </Popover.Button>
            <Popover.Panel className={styles.disclosurePanel}>
              <ul className="p-4">
                {items.map((child, index) => (
                  <li key={index}>
                    <Link to={`${child.url_key}`}>{child.name}</Link>
                  </li>
                ))}
              </ul>
            </Popover.Panel>
          </>
        )}
      </Popover>
    );
  }

  return (
    <MobileLink
      name={name}
      image={image}
      url_key={url_key}
      classes="text-[15px]"
      children={hasChildren ? null : null}
    />
  );
};

const BottomStrip = () => {
  const bottomStripNavigation = useMemo(() => {
    const navigation = globalThis?.NAVIGATION?.map(item => ({
      ...item,
      name: MAPPING?.[item?.name],
    }));
    navigation.push({
      name: 'Support',
      url_key: '/support',
    });
    return navigation;
  }, []);

  return (
    <div className={styles.bottomStrip}>
      <ul className={styles.bottomStripList}>
        {bottomStripNavigation?.map((item, index) => (
          <BottomMenuItemContainer
            item={item}
            key={index}
            uniqueKey={index}
            image={BOTTOM_STRIP_IMAGES[index]}
          />
        ))}
      </ul>
    </div>
  );
};

export default BottomStrip;
